import { createSlice } from '@reduxjs/toolkit';
import { downloadReport, getReports } from 'store/asyncActions/downloadAsyncAction';
import apiStatus from 'utils/apiStatus';

//*reducer handle
const DownloadSlice = createSlice({
  name: 'ArchiverReport',
  initialState: {
    status: null,
    error: null,
    message: null,
    listReports:[],
    recordLength:null,
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    clearDownloadArchiverReports: (state, action) => {
      state.status = null;
      state.error = null;
      state.message = null;
      state.listReports = [];
      state.recordLength = null;
    },
  },
  extraReducers: {
    //* Get Reports
    // eslint-disable-next-line no-unused-vars
    [getReports.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    [getReports.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.listReports = state.listReports.concat(action.payload.listReport);
      state.recordLength = action.payload.length;
    },
    [getReports.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.error.message;
    },

    //* Download Report
    // eslint-disable-next-line no-unused-vars
    [downloadReport.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    [downloadReport.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
    },
    [downloadReport.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.error.message;
    },
  }
});

const { actions, reducer } = DownloadSlice;

const { 
  clearDownloadArchiverReports,
} = actions;

export {
  clearDownloadArchiverReports,
}
export default reducer;
