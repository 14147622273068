import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';
import apiStatus from 'utils/apiStatus';
import validatePermission from 'utils/validatePermission';

const AuthGuard = props => {
  const { permissions, children } = props;

  const userPermissions = useSelector(state => state.session.userInfo.permissions);
  const sessionStatus = useSelector(state => state.session.status);
  const sessionError = useSelector(state => state.session.error);
  const router = useRouter();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const newAccessToken = urlParams.get('accessToken');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const automationLoginUrl = process.env.REACT_APP_LOGIN_PAGE;
    const accessToken = localStorage.getItem('accessToken');

    const originURL = window.location.href;

    if (!newAccessToken) {
      if (!accessToken) window.location.href = automationLoginUrl + '?return_url=' + originURL;
    } else {
      localStorage.setItem('accessToken', newAccessToken);
      window.location.href = originURL.split('?')[0];
    }

    // eslint-disable-next-line
  }, [sessionStatus, sessionError, router]);

  useEffect(() => {
    if (sessionStatus !== apiStatus.PENDING && validatePermission(userPermissions, permissions)) {
      router.history.push('/errors/error-401');
      return;
    }

    // eslint-disable-next-line
  }, [router, sessionStatus]);

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.array.isRequired,
  reRender: PropTypes.bool
};

AuthGuard.defaultProps = {
  permissions: []
};

export default AuthGuard;
