/* eslint-disable no-unused-vars */
import axios from 'apis/axiosClient';

const getArchiverReportInfo = (data) => {
  console.log(data);
  // return data;
  return axios.get(`/report/archiver-sms/export?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`);
}

const downloadArchiverReport = (id) => {
  console.log(id);
  return axios.get(`/report/archiver-sms/download/${id}`);
}

const getListReport = (data) => {
  return axios.get(`/report/archiver-sms/${data.count}`);
}

export default {
  getArchiverReportInfo,
  downloadArchiverReport,
  getListReport
}
