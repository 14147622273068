import sessionReducer from 'store/slices/sessionSlice';
import reportReducer from 'store/slices/reportSlice';
import downloadReducer from 'store/slices/downloadSlice'

const rootReducer = {
  session: sessionReducer,
  report: reportReducer,
  download: downloadReducer
};

export default rootReducer;
