export default () => {
  return [
    {
      title: 'REPORT',
      href: '/report',
    },
    {
      title: 'DOWNLOAD',
      href: '/download',
    },
  ];
};
