import { reportApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

//*thunk action

const getReports = createAsyncThunk(
  'ArchiverReport/getReport',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await reportApi.getListReport(data);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().report.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const downloadReport = createAsyncThunk(
  'ArchiverReport/downloadReport',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await reportApi.downloadArchiverReport(data);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().report.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

export {
  downloadReport,
  getReports
}
