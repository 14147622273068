import axios from 'axios';

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    if(config.headers['Authorization'] === null || config.headers['Authorization'] === '' || config.headers['Authorization'] === undefined) {
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      const automationLoginUrl = process.env.REACT_APP_LOGIN_PAGE;
      const originURL = window.location.origin;
      window.location.href = automationLoginUrl + '?return_url=' + originURL;
    }
    return Promise.reject(error);
  }
);
export default instance;
