/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

//Layout
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import { AuthGuard } from './components';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <AuthGuard><Redirect to="/report" /></AuthGuard>
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        // eslint-disable-next-line react/no-multi-comp
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '*/',
    component: DashboardLayout,
    routes: [
      {
        path: '/report',
        // exact: true,
        component: lazy(() => import('views/Report')),
      },
      {
        path: '/download',
        exact: true,
        component: lazy(() => import('views/Download')),
      }
    ]
  },
]

export default routes;
