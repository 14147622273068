import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'store';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import routes from 'routes';
import './assets/scss/index.scss';
import { ScrollReset } from 'components';

// const history = createBrowserHistory();

function App() {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollReset />
          {renderRoutes(routes)}
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
