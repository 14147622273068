/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import {
  Toolbar,
  AppBar,
  Avatar,
  Typography,
  Button,
  List,
} from '@material-ui/core';
import { Img } from 'react-image'
import InputIcon from '@material-ui/icons/Input';
import AppMenuItem from './components/AppMenuItem';
import navigationConfig from './navigationConfig';
import {DialogConfirm} from 'components';
import { useSelector } from 'react-redux';

const navigation = navigationConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    position: 'relative',
    padding: '0px',
    height: 'auto'
  },
  avatar: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginLeft: '10px'
  },
  appName: {
    color: 'white',
    fontSize: '20px',
    marginLeft: theme.spacing(2),
  },
  avtLogo: {
    marginRight: theme.spacing(2),
  },
  logoutButton:{
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  logoutIcon: {
    marginRight: '8px'
  },
  listMenuWrap: {
    flex: 1,
    height: '100%',
  },
  listMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0px',
    margin: '0px',
    height: '64px'
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const [open,setOpen] = useState(false);

  const userInfo = useSelector(state => state.session.userInfo);

  const handleLogout = () => {
    const automationLoginUrl = process.env.REACT_APP_LOGIN_PAGE;
    localStorage.removeItem('accessToken');
    window.location.href = automationLoginUrl + '?return_url=' + window.location.protocol + '//' + window.location.host +'/report/manage&is_log_out=true';
  };


  const openDialog = () => {
    setOpen(true);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  return (
    <AppBar
      className={classes.root}
      position="static"
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          className={classes.avtLogo}
          color="inherit"
          edge="start"
        >
          <Avatar
            alt="Life Processing"
            src="/images/logo-128.png"
          />

          <Typography
            className={classes.appName}
            component="h2"
            variant="h3"
          >
            RINGCENTRAL ARCHIVER
          </Typography>
        </IconButton>

        { 
          <>
            <div className={classes.listMenuWrap}>
              <List className={classes.listMenu}>
                {navigation.map((item, index) => (              
                  <AppMenuItem
                    children={item.children}
                    href={item.href}
                    index={index}
                    isChoose={false}
                    key={index}
                    title={item.title}
                  />
                ))}
              </List>
            </div>
            {userInfo.id && (
              <Img
                alt="avatar"
                className={classes.avatar}
                src={[
                  `${process.env.REACT_APP_AUTOMATION_CORE_BACKEND}/public/images/avatars/${userInfo.id}.png`,
                  '/images/default_avatar.png'
                ]}
              />
            )}
            <div>
              <Button
                className={classes.logoutButton}
                color="inherit"
                fullWidth
                onClick={openDialog}
              >
                <InputIcon className={classes.logoutIcon} />
                  Sign out
              </Button>
            </div>
            <DialogConfirm
              handleClose={closeDialog}
              handleConfirm={handleLogout}
              isConfirm
              message="You will be Logout !!!"
              open={open}
            />
          </>
        }
      </Toolbar>
    </AppBar>
  )
}

export default NavBar;

